import React, { useState, useContext, useEffect } from "react";
import { IoMdClose } from "react-icons/io"; // Importing close icon
import Helper from "../../helper";

const PlaceBet = (props) => {
    const userInfo = Helper(); // get login user details

    // Dynamic background based on type
    const backgroundColor = props?.type === "back" ? "rgb(179, 209, 235)" : "#ecb7de";

    return (
        <div className="placebet-container" style={{ backgroundColor }}>
            <div className="placebet-header">
                <IoMdClose className="close-icon" onClick={() => props?.handleClosePlaceBet()} color="black" style={{ marginTop: 10, marginRight: 10 }} />
            </div>
            <div className="placebet-description">
                <span>
                    {/* <p>{betSource}</p>  */}
                    <p style={{ fontSize: '12px', fontWeight: '500' }}>{props?.type === "back" ? "Back" : "Lay"}: {props?.BetPlaceData?.runner_name}</p> {/* Display teamName */}
                    {/* <p>Match Odds: {matchOdds}</p> Display match odds */}
                </span>
            </div>
            <div className="placebet-boxes">
                <div className="rate-box">
                    {/* Rate/Odds input box */}
                    <div className="inc-dec-button">
                        <button id="decrease" className="btn">-</button>
                        <input
                            type="number"
                            value={props?.BetPlaceData.price}// Display the match odds value in this input field
                            readOnly // Make it read-only
                        />
                        <button id="increase" className="btn">+</button>
                    </div>
                    {/* Bet amount input box */}
                    <div className="input-number-box">
                        <button id="decrease" className="btn"
                            onClick={() => props?.placeStakeValue(props?.StakeValue > 0 ? props?.StakeValue - 100 : console.log("negative"))}>
                            -
                        </button>
                        <input
                            type="number"
                            value={props?.StakeValue ? props?.StakeValue : ''} // Display betAmount in this input field
                            min="0"
                            onChange={(e) => props?.placeStakeValue(e.target.value)}// Allow user to edit the amount
                        />
                        <button id="increase" className="btn" onClick={() => props?.placeStakeValue(props?.StakeValue + 100)}>
                            +
                        </button>
                    </div>
                </div>
            </div>
            {/* Price buttons */}
            <div className="price-boxes">
                {props?.chips?.map((chip) => (
                    <button key={chip?.chip_value} className="price-box-1" onClick={() => props?.placeStakeValue(chip?.chip_value)}>
                        {chip?.chip_value}
                    </button>
                ))}
            </div>
            {/* Action buttons */}
            {
                userInfo &&
                <div className="action-buttons">
                    <button className="cancel-btn" onClick={() => { props?.setShowBetsSlip(false); props?.setSelection_id(''); props?.setHtmlId(''); props?.handleClosePlaceBet(); }}>
                        Cancel
                    </button>
                    <button className="placebet-btn" onClick={() => props?.placing ? console.log('Placing....') : props?.betPlace()}>
                        {props?.placing ? "Placing...." : "Place Bet"}
                    </button>
                </div>
            }

        </div>
    );
};

export default PlaceBet;
