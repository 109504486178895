import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiSolidCricketBall, BiFootball, BiTennisBall } from 'react-icons/bi';
// import './CricketList.css';
import "../../../src/App.css";
import Header from '../header/Header';
import Footer from '../Footer/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { getAllEvents } from '../../redux/slice/event/eventSlice';
import Helper from "../../helper";
import TableRow from '../TableRow/index'
import ExposureHeader from '../ExposureHeader/ExposureHeader';



const CricketList = () => {
  const dispatch = useDispatch();
  const userInfo = Helper();
  const userInfos = useSelector((state) => state.events); // Use selector to access state
  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(new Date('2024-08-26T04:30'));
  const scrollRefs = useRef([]);
  const [eventType, setEventType] = useState(4)
  const [isEventLoading, setIsEventLoading] = useState(true)
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);


  useEffect(() => {
    dispatch(getAllEvents({
      user_id: userInfo ? userInfo._id : "",
    }));
  }, [])


  useEffect(() => {
    if (userInfos.events.length) {
      const newEvents = userInfos?.events[0]?.competitions[0]?.events || [];
      setIsEventLoading(false);
      if (newEvents.length > 0 && (allEvents.length == 0 || JSON.stringify(newEvents) !== JSON.stringify(allEvents))) {
        setAllEvents(newEvents);
      }
    }
  }, [userInfos.events.length])

  const filteredEvents = useMemo(() => {
    if (allEvents.length > 0) {
      return allEvents.filter(event => event.event_type == eventType);
    }
    return [];
  }, [allEvents, eventType]);

  useEffect(() => {
    setEvents(filteredEvents);
  }, [filteredEvents]);

  const initializeScrollRefs = (index) => {
    if (!scrollRefs.current[index]) {
      scrollRefs.current[index] = React.createRef();
    }
  };

  const handleScroll = (scrollingRef) => {
    scrollRefs.current.forEach(ref => {
      if (ref.current && ref !== scrollingRef) {
        ref.current.scrollLeft = scrollingRef.current.scrollLeft;
      }
    });
  };

  // Move handleMatchClick here, outside of useEffect
  const handleMatchClick = () => {
    navigate('/matchupdates'); // Navigate to MatchUpdates
  };

  useEffect(() => {
    scrollRefs.current.forEach((ref) => {
      if (ref.current) {
        ref.current.addEventListener('scroll', () => handleScroll(ref));
      }
    });

    return () => {
      scrollRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.removeEventListener('scroll', () => handleScroll(ref));
        }
      });
    };
  }, []);

  return (
    <>
      <Header />
      <ExposureHeader/>
      <div className='main'>
        {/* <ul className='live'>
          <li className='live-li'>LIVE</li>
          <li>My Bets(0)</li>
        </ul> */}

        {/* Cricket Section */}
        {events?.length > 0 && (
          <div className="cricket">
            <div className="header-box">
              {
                eventType == "4" ?
                  <>
                    <BiSolidCricketBall size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
                    <span>Cricket</span>
                  </>
                  : eventType == "2" ?
                    <>
                      <BiTennisBall size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
                      <span>Tennis</span>
                    </>
                    : eventType == "1" ?
                      <>
                        <BiFootball size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
                        <span>Football</span>
                      </>
                      :
                      <></>
              }

            </div>
            <div className="bet-numbers">
              <div className="box"><span>1</span></div>
              <div className="box"><span>X</span></div>
              <div className="box"><span>2</span></div>
            </div>
          </div>
        )}

        {events?.map((event, index) => (
          <TableRow i={index} row={event} />
        ))}
      </div>
      <Footer />
    </>
  );
}

export default CricketList;
