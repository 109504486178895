import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import MainScreen from './components/MainScreen/MainScreen';
import MatchUpdates from './components/MatchUpdates/MatchUpdates';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import AccountStatus from './components/AccountStatus/AccountStatus';
import PlaceBet from './components/PlaceBet/index';
// import AfterLogin from './components/AfterLogin/AfterLogin';
import AccountStatement from './components/AccountStatement/AccountStatement';
import Deposit from './components/Deposit/Deposit';
import Withdraw from './components/Withdraw/Withdraw';
import MyMarkets from './components/MyMarkets/MyMarkets';
import CricketList from './components/CricketList/CricketList';
import FootballList from './components/FootballList/FootballList';
import TennisList from './components/TennisList/TennisList';
import HorseRacingList from './components/HorseRacingList/HorseRacingList';
import GrayHoundRacingList from './components/GrayHoundRacingList/GrayHoundRacingList';
import IndianCardGamesList from './components/IndianCardGamesList/IndianCardGamesList';
import OpenBets from './components/OpenBets/OpenBets';
import ProfitAndLoss from './components/ProfitAndLoss/ProfitAndLoss';
import ChangePassword from './components/ChangePassword/ChangePassword';
import Settings from './components/Settings/Settings';
import Profile from './components/Profile/Profile';
import { FaN } from 'react-icons/fa6';
import FancyMarket from './components/FancyMarket/FancyMarket';
import ProfitAndLoss2 from './components/ProfitAndLoss/ProfitAndLoss2';
import ProfitAndLoss3 from './components/ProfitAndLoss/ProfitAndLoss3';
import Rules from './components/Rules/Rules';
import AddWithdrawDetails from './components/AddWithdrawDetails/AddWithdrawDetails';
import BankForm from './components/AddWithdrawDetails/BankForm';
import PaytmForm from './components/AddWithdrawDetails/PaytmForm';
import UpiForm from './components/AddWithdrawDetails/UpiForm';
import PhonepeForm from './components/AddWithdrawDetails/PhonepeForm';
import GpayForm from './components/AddWithdrawDetails/GpayForm';
import Empty from './components/EmptyDiv/Empty';
import ReferAndEarn from './components/ReferAndEarn/ReferAndEarn';
import { TimeProvider } from './components/TimeContext/TimeContext';
import PlaceBetSuccessfull from './components/PlaceBetSuccesfull/PlaceBetSuccessfull';
import ScrollCasino from './components/ScrollCasino/ScrollCasino';
import UpcomingEvents from './components/UpcomingEvents/UpcomingEvents';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from "./components/AuthContext";
import { WebSocketContext, socket } from './components/Context/websocket';
import ProfitLossEvents from './components/profit-loss-events/index'
import ProfitLossEventMarkets from './components/profit-loss-event-markets/index'
import ProfitLossBetHistory from './components/profit-loss-bet-history/index'


function App() {

  return (
    <AuthProvider>
      <TimeProvider>
        <Router>
          <WebSocketContext.Provider value={socket}>
            <Routes>
              <Route path='/' element={<MainScreen />} />

              <Route path='/matchupdates/:event_id/:is_inplay' element={<MatchUpdates />} />
              {/* <Route path='/matchupdates' element={<PrivateRoute><MatchUpdates /></PrivateRoute>} /> */}
              <Route path='upcomingevents' element={<PrivateRoute><UpcomingEvents /></PrivateRoute>} />
              <Route path='/accountstatus' element={<PrivateRoute><AccountStatus /></PrivateRoute>} />
              <Route path='/placebet' element={<PrivateRoute><PlaceBet /></PrivateRoute>} />
              <Route path='/accountstatement' element={<PrivateRoute><AccountStatement /></PrivateRoute>} />
              <Route path='/deposit' element={<PrivateRoute><Deposit /></PrivateRoute>} />
              <Route path='/withdraw' element={<PrivateRoute><Withdraw /></PrivateRoute>} />
              <Route path='/mymarkets' element={<PrivateRoute><MyMarkets /></PrivateRoute>} />
              <Route path='/cricketlist' element={<PrivateRoute><CricketList /></PrivateRoute>} />
              <Route path='/football-list' element={<PrivateRoute><FootballList /></PrivateRoute>} />
              <Route path='/tennislist' element={<PrivateRoute><TennisList /></PrivateRoute>} />
              <Route path='/horseracinglist' element={<PrivateRoute><HorseRacingList /></PrivateRoute>} />
              <Route path='/grayhoundracinglist' element={<PrivateRoute><GrayHoundRacingList /></PrivateRoute>} />
              <Route path='/indiancardgameslist' element={<PrivateRoute><IndianCardGamesList /></PrivateRoute>} />
              <Route path='/openbets' element={<PrivateRoute><OpenBets /></PrivateRoute>} />


              <Route path='/profitandloss' element={<PrivateRoute><ProfitAndLoss /></PrivateRoute>} />
              <Route path='/profit-loss-event/:event_type_id/:from_date/:to_date' element={<PrivateRoute><ProfitLossEvents /></PrivateRoute>} />
              <Route path='/profit-loss-event-market/:match_id/:from_date/:to_date' element={<PrivateRoute><ProfitLossEventMarkets /></PrivateRoute>} />
              <Route path='/profit-loss-bet-history/:match_id/:market_id/:from_date/:to_date' element={<PrivateRoute><ProfitLossBetHistory /></PrivateRoute>} />

              <Route path='/changepassword' element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
              <Route path='/settings' element={<PrivateRoute><Settings /></PrivateRoute>} />
              <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
              <Route path='/fancymarket' element={<PrivateRoute><FancyMarket /></PrivateRoute>} />
              <Route path='/profitandloss2' element={<PrivateRoute><ProfitAndLoss2 /></PrivateRoute>} />
              <Route path='/profitandloss3' element={<PrivateRoute><ProfitAndLoss3 /></PrivateRoute>} />
              <Route path='/rules' element={<PrivateRoute><Rules /></PrivateRoute>} />
              <Route path='/addwithdrawdetails' element={<PrivateRoute><AddWithdrawDetails /></PrivateRoute>} />
              <Route path='/bankform' element={<PrivateRoute><BankForm /></PrivateRoute>} />
              <Route path='/paytmform' element={<PrivateRoute><PaytmForm /></PrivateRoute>} />
              <Route path='/upiform' element={<PrivateRoute><UpiForm /></PrivateRoute>} />
              <Route path='/phonepeform' element={<PrivateRoute><PhonepeForm /></PrivateRoute>} />
              <Route path='/gpayform' element={<PrivateRoute><GpayForm /></PrivateRoute>} />
              <Route path='/emptydiv' element={<PrivateRoute><Empty /></PrivateRoute>} />
              <Route path='/referandearn' element={<PrivateRoute><ReferAndEarn /></PrivateRoute>} />
              <Route path='/placebetsuccessfully' element={<PrivateRoute><PlaceBetSuccessfull /></PrivateRoute>} />
              <Route path='/scrollcasino' element={<PrivateRoute><ScrollCasino /></PrivateRoute>} />
            </Routes>
          </WebSocketContext.Provider>
        </Router>
      </TimeProvider>
    </AuthProvider>
  );
}
// };

export default App;
