import "./Settings.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import { useEffect, useState } from 'react';
import Appconfig from '../../config/config'
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Helper from "../../helper";
import ExposureHeader from '../ExposureHeader/ExposureHeader';


function Settings() {
  const userInfo = Helper(); // get login user details

  const navigate = useNavigate();
  const [chipList, setchipList] = useState([]);

  useEffect(() => {
    getChips();
  }, [])

  const getChips = async () => {
    var data = JSON.stringify({
      "user_id": userInfo._id
    });
    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}chips/getChips`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        try {
          setchipList(response.data);
          // let tmpChipList = response.data;
          // const newChipList = tmpChipList.map((chipList, index) => {
          //     tmpChipList[index]["id"] = index + 1;
          // });
          // setchipList(tmpChipList);
        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        // postErrorToslack(error.message);
        console.log(error);
      });
  }


  const handleInputChange = (event) => {
    let index = event.target.getAttribute('data-id');
    console.clear();
    console.log('index', index)
    // const newChipList = chipList.map((chip, index) => {
    //     if(index == tempIndex)
    //     {
    //         console.log('chipList1',chip);
    //         chipList[index]["chip_value"] =  parseFloat(event.target.value)
    //     }
    // });
    console.log('before', chipList[index])
    chipList[index]["chip_value"] = parseFloat(event.target.value == '' ? 0 : event.target.value);
    console.log('after', chipList[index])
    setchipList([
      ...chipList,
    ]);
    console.log('chipList', chipList);
  }

  function updateChips(chipList) {
    let newdata = [];
    for (var i = 0; i < chipList.length; i++) {
      newdata[i] = { "_id": chipList[i]._id, "chip_value": document.getElementById('stakeEdit_8' + chipList[i]._id).value };
    }
    var data = JSON.stringify({
      "data": newdata
    });
    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}chips/updateMultipleChips`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        try {
          if (response.data.ok) {
            NotificationManager.success('Stake Update Successfully!', '', 3000);
            getChips();
            setTimeout(() => {
              navigate('/');
            }, 2000)

          }
        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        // postErrorToslack(error.message);
        console.log(error);
      });
  }


  return (
    <div>
      <Header />
      <ExposureHeader/>
      <div className="setting">
        <div className="settings-container">
          <span>Settings</span>
        </div>
        <p className="odds">
          <span>Edit Stakes</span>
        </p>
        <div className="edit-stakes">
          {chipList.map((row, index) => (
            <label key={index}>
              <input
                id={`stakeEdit_8${row._id}`}
                type='text'
                pattern="[0-9]*"
                data-id={index}
                value={row.chip_value}
                onChange={handleInputChange}
              />
            </label>
          ))}
        </div>

        {/* Edit button */}
        {
          chipList.length > 0 &&
          <div className="edit-button">
            <button onClick={() => updateChips(chipList)} className="edit-btn">
              Save
            </button>
          </div>
        }
      </div>
      <Footer />
    </div>
  );
}

export default Settings;
