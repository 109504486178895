import React, { useState } from 'react';
import "./MyMarkets.css";
import { useNavigate } from 'react-router-dom';
import { PiTennisBallDuotone, PiSoccerBallBold } from "react-icons/pi";
import { BiSolidCricketBall } from "react-icons/bi";
import Header from '../header/Header';
import Footer from '../Footer/Footer';
import ExposureHeader from '../ExposureHeader/ExposureHeader';


const MyMarkets = () => {

  const [showMarket, setShowMarket] = useState(false);
  const navigate = useNavigate();

  const handleMarket = () => {
    setShowMarket(true);
    navigate('/matchupdates')
  }

  return (
    <div>
      <Header />
      <ExposureHeader/>
      <div className='account-statement'>
        <p className="open-para">
          <span>My Markets</span>
        </p>
        {/* <div className="market-match">
          <p><BiSolidCricketBall size={30} color="black" style={{ marginRight: 10, marginLeft: 10 }} />
            <button onClick={handleMarket} className='v/s'><span className='mrkt-match'>India <strong>VS</strong> Pakistan</span></button>
          </p>
          <p><PiSoccerBallBold size={30} color="black" style={{ marginRight: 10, marginLeft: 10 }} />
            <button onClick={handleMarket} className='v/s'><span className='mrkt-match'>Thunder Strikers<strong>VS</strong> Blaze Titans</span></button>
          </p>
          <p><PiTennisBallDuotone size={30} color="black" style={{ marginRight: 10, marginLeft: 10 }} />
            <button onClick={handleMarket} className='v/s'><span className='mrkt-match'>Ball Busters<strong>VS</strong> Net Nuts</span></button>
          </p>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default MyMarkets;
