import { useState, useEffect } from 'react';
import { Input, Table, Select, Pagination } from 'antd';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import Appconfig from '../../config/config'
import { useDispatch, useSelector } from 'react-redux';
import Helper from "../../helper";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from '../ExposureHeader/ExposureHeader';


function ProfitLossEventMarkets() {
    const params = useParams();
    const userInfo = Helper(); // get login user details

    const [filteredData, setFilteredData] = useState([]);
    const [formData, setFormData] = useState({
        "user_id": userInfo && userInfo?._id,
        "match_id": params && params?.match_id,
        "from_date": params && params?.from_date,
        "to_date": params && params?.to_date,
    })

    const columns = [
        {
            title: 'Sport Name',
            sorter: true,

            render: (_, record) => (
                record.event_type == 4 ?
                    (
                        <div className=' '>
                            Cricket
                        </div>
                    ) : record.event_type == 2 ? (
                        <div className=' '>
                            Tennis
                        </div>
                    ) : record.event_type == 1 ? (
                        <div className=' '>
                            Soccer
                        </div>
                    ) : record.event_type == 7 ? (
                        <div className=' '>
                            Horse Racing
                        </div>
                    ) : record.event_type == 1004 || record.event_type == 1005 || record.event_type == 1007 || record.event_type == 1008 || record.event_type == 1009 ? (
                        <div className=' '>
                            Casino
                        </div>
                    ) : record.event_type == 4339 ? (
                        <div className=' '>
                            Greyhound Racing
                        </div>
                    ) : record.event_type == 27979456 ? (
                        <div className=' '>
                            Kabaddi
                        </div>
                    ) : record.event_type == 7522 ? (
                        <div className=' '>
                            Basketball
                        </div>
                    ) : record.event_type == 2378961 ? (
                        <div className=' '>
                            Politics
                        </div>
                    ) : record.event_type == 66101 ? (
                        <div className=' '>
                            Virtual
                        </div>
                    ) : record.event_type == 66104 ? (
                        <div className=' '>
                            Lottery
                        </div>
                    ) : (
                        <div className=' '>
                            Other
                        </div>
                    )
            ),
        }, {
            title: 'Event Name',
            sorter: true,

            render: (_, record) => (
                <div className='' >
                    {record.event_name}
                </div>
            ),
        },
        {
            title: 'Market Id',
            sorter: true,

            render: (_, record) => (
                record.market_name == "Fancy" ? (
                    <div className=''>
                        {/* {record.selection_id} */} -
                    </div>
                ) : (
                    <div className=''>
                        {record.market_id}
                    </div>
                )

            ),
        }, {
            title: 'Market Name',
            sorter: true,

            render: (_, record) => (

                <Link className='' to={`/profit-loss-bet-history/${record.match_id}/${record.market_id ? record.market_id : 'Fancy'}/${params?.from_date}/${params?.to_date}`}>
                    {record.market_name}
                </Link>

            ),
        },
        {
            title: 'Result',
            sorter: true,

            render: (_, record) => (
                <div className=''>
                    {record?.result_name ? record?.result_name : '-'}
                </div>
            ),
        },
        {
            title: 'Profit/Loss',
            dataIndex: 'name',
            sorter: true,
            render: (_, record) => (
                record.totalPL < 0 ? (
                    <span className=' '>
                        {record?.totalPL?.toFixed(2)}
                    </span>
                ) : (
                    <span className=' '>{
                        record?.totalPL?.toFixed(2)
                    }</span>
                )
            )
        },
        // {
        //     title: 'Commission',
        //     dataIndex: 'name',
        //     sorter: true,
        //     render: (_, record) => (
        //         <span className=''> {record?.commissionAmount?.toFixed(2)}</span>
        //     )
        // },
        {
            title: 'Settle Time',
            sorter: true,

            render: (_, record) => (
                <div className=''>
                    {new Date(record.settledTime).toLocaleString()}
                </div>
            ),
        },
    ];


    useEffect(() => {
        filterProfitLossByEventMarkets();
    }, [])


    const filterProfitLossByEventMarkets = async (e) => {

        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/auraProfitLossByEventMarkets`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    console.log(response.data.resultData);
                    setFilteredData(response.data.resultData);

                } catch (e) {
                    // postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                // postErrorToslack(error.message);
            });
    }


    return (
        <>
            <Header />
            <ExposureHeader/>
            <div className="account-statment">
                <p className="open-para">
                    <span>Profit & Loss By Event Markets</span>
                </p>
            </div>


            <div className="table-container">
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={true}
                    bordered={true}
                />
            </div>

            <Footer />
        </>
    );

}

export default ProfitLossEventMarkets;
