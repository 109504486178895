import React from "react";
import "./ChangePassword.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import Helper from "../../helper";
import { useEffect, useState } from 'react';
import Appconfig from '../../config/config'
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { useAuth } from "../AuthContext";
import { useNavigate } from 'react-router-dom';
import ExposureHeader from "../ExposureHeader/ExposureHeader";


const ChangePassword = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const userInfo = Helper(); // get login user details

  const [formData, setformData] = useState({
    user_id: userInfo?._id,
    name: userInfo?.name,
    user_name: userInfo?.user_name,
    user_type: userInfo?.user_type,
    old_password: '',
    new_password: '',
    con_password: '',

  });

  const [user_nameValidationError, setUser_NameValidationError] = useState("Please enter old password");
  const [passwordValidationError, setPasswordValidationError] = useState("Please enter new password");
  const [conpasswordValidationError, setConPasswordValidationError] = useState("Please check your confirm password");
  const [button, setButton] = useState(false)


  function checkValidation() {

    if (formData.old_password.trim() === "") {

      setUser_NameValidationError('Please enter old password')
      document.querySelector('.oldpassword-error').classList.remove('hidden');
      // console.log('hlw');

    }
    if (formData.new_password.trim() === "") {
      setPasswordValidationError('Please enter new password')
      document.querySelector('.newpassword-error').classList.remove('hidden');
    }
    if (formData.con_password.trim() === formData.new_password.trim()) {
      document.querySelector('.conpassword-error').classList.remove('hidden');
      setConPasswordValidationError('Please check your confirm password')
    }

    if (formData.old_password !== "" && formData.new_password !== "" && formData.con_password !== "" && formData.new_password === formData.con_password) {
      return true
    }
    return false;

  }

  function setOldPassword(value) {
    setformData({
      ...formData,
      old_password: value
    })
  }

  function setNewPassword(value) {
    setformData({
      ...formData,
      new_password: value
    })
  }

  function setConPassword(value) {
    setformData({
      ...formData,
      con_password: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkValidation()) {
      formData.master_id = userInfo?.master_id;
      formData.masters = userInfo?.masters;

      setButton(true);
      // console.log('formData', formData);
      // return false;
      var config = {
        method: 'post',
        url: `${Appconfig.apiUrl}users/auraChangePassword`,
        headers: {
          'Content-Type': 'application/json',

        },

        data: JSON.stringify(formData)

      };
      axios(config)
        .then(function (response) {

          if (response.data.result) {
            NotificationManager.success(response.data.message, '', 3000);
            setButton(false);

            formData.old_password = '';
            formData.new_password = '';
            formData.con_password = '';

            setTimeout(() => {
              logout();

              navigate('/');
            }, 3000)

          } else {
            NotificationManager.error(response.data.message, '', 3000);

          }


        })
        .catch(function (error) {
          console.log(error);
        });

    }


  }

  return (
    <div>
      <Header />
      <ExposureHeader/>
      <div className="account-statement">
        <p className="open-para">
          <span>Change Password</span>
        </p>
        <div className="change-password-container">
          <form onSubmit={(e) => handleSubmit(e)}>
            <label>Old Password </label>
            <input
              type="text"
              onChange={(e) => setOldPassword(e.target.value)}
              required
              name="oldPassword"
              placeholder="Enter Old Password"
            />
            <div className='oldpassword-error hidden'>{user_nameValidationError}</div>
            <label>New Password </label>

            <input
              type="text"
              onChange={(e) => setNewPassword(e.target.value)}
              required
              name="newPassword"
              placeholder="Enter New Password"
            />
            <div className=' newpassword-error hidden'>{passwordValidationError}</div>
            <label>Confirm Password </label>

            <input
              type="text"
              onChange={(e) => setConPassword(e.target.value)}
              required
              name="confirmPass"
              placeholder="Enter New Password Again"
            />
            <div className='conpassword-error hidden'>{conpasswordValidationError}</div>
            <button className="change-password-button" type="submit" disabled={button} >Submit</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
