import React from "react";
import "./OpenBets.css"; // Include the CSS in a separate file or use inline styles
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import { CiSearch } from "react-icons/ci";
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAllBets } from '../../redux/slice/openBet/openBetSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Appconfig from '../../config/config';
import axios from 'axios';
import Helper from "../../helper";
import { useNavigate } from 'react-router-dom';
import { Table } from "antd";
import ExposureHeader from '../ExposureHeader/ExposureHeader';

function OpenBets() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = Helper(); // get login user details
  const openbets = useSelector((state) => state.bets); // Use selector to access state
  const [fancyBets, setFancyBets] = useState([]);
  const [matchBets, setMatchBets] = useState([]);
  console.log('matchBets', matchBets);
  console.log('fancyBets', fancyBets);

  const getOpenBetsByEvent = async () => {
    dispatch(getAllBets({
      user_id: userInfo?._id,
    }))
  }

  useEffect(() => {
    getOpenBetsByEvent()
  }, [])

  useEffect(() => {
    if (openbets?.bets?.length > 0) {
      let openBetsData = openbets?.bets;
      let fancyOpenBets = openBetsData?.filter(fancy => fancy.betting_type == "Fancy");
      let matchOpenBets = openBetsData?.filter(match => match.betting_type == "Match");
      setFancyBets(fancyOpenBets);
      setMatchBets(matchOpenBets);
    }
  }, [openbets])

  const columns = [
    {
      title: 'Event Name',
      dataIndex: 'event_name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.event_name}</span>
        </>
      ),
    },
    {
      title: 'Market Type',
      dataIndex: 'market_name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.market_name}</span>
        </>
      ),
    },
    {
      title: 'Selection',
      dataIndex: 'runner_name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.runner_name}</span>
        </>
      ),
    },
    {
      title: 'Bet Type',
      dataIndex: 'is_back',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.is_back ? 'Back' : 'Lay'}</span>
        </>
      ),
    },
    {
      title: 'Stack',
      dataIndex: 'stake',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.stake}</span>
        </>
      ),
    },

    {
      title: 'Round ID',
      dataIndex: 'betting_id',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?._id}</span>
        </>
      ),
    },
    {
      title: 'Place Time',
      dataIndex: 'createdAt',
      sorter: true,
      render: (_, record) => (
        <>
          <span>
            {new Date(record?.createdAt).toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })}
          </span>
        </>
      ),
    },
  ];

  
  const columnFancy = [
    {
      title: 'Event Name',
      dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.remarks}</span>
        </>
      ),
      width: '20%',
    },
    {
      title: 'Market Type',
      dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.market_name}</span>
        </>
      ),
      width: '20%',
    },
    {
      title: 'Selection',
      dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.runner_name}</span>
        </>
      ),
      width: '20%',
    },
    {
      title: 'Bet Type',
      dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.is_back ? 'Back' : 'Lay'}</span>
        </>
      ),
      width: '20%',
    },
    {
      title: 'Stack',
      dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.stake}</span>
        </>
      ),
      width: '20%',
    },

    {
      title: 'Round ID',
      dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>{record?.betting_id}</span>
        </>
      ),
      width: '20%',
    },
    {
      title: 'Place Time',
      dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        <>
          <span>
            {new Date(record?.createdAt).toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })}
          </span>
        </>
      ),
      width: '20%',
    },
  ];


  return (
    <>
      <Header />
      <ExposureHeader/>
      <div className="open-bet">
        <p className="open-para">
          <span>Open Bets</span>
        </p>
        <p className="odds">
          <span>Match Odds</span>
        </p>
        <div className="table-container">
          <Table
            columns={columns}
            dataSource={matchBets}
            pagination={true}
            bordered={true}
          />
        </div>


        <p className="odds">
          <span>Session</span>
        </p>

        <div className="table-container">
          <div className="table-container">
            <Table
              columns={columnFancy}
              dataSource={fancyBets}
              pagination={true}
              bordered={true}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default OpenBets;
