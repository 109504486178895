import React, { useEffect, useState } from "react";
import "./AccountStatement.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from "../ExposureHeader/ExposureHeader";
import Empty from "../EmptyDiv/Empty";
import { Table } from "antd";
import Helper from "../../helper";
import moment from 'moment';
import axios from 'axios';
import Appconfig from '../../config/config'
// import 'bootstrap/dist/css/bootstrap.min.css';

const AccountStatement = () => {
  const userInfo = Helper(); // get login user details

  const [data, setData] = useState([]);


  const [formData, setFormData] = useState({
    "user_id": userInfo && userInfo?._id,
    "type": "0",
    "from_date": moment().subtract(1, 'days').format('YYYY-MM-DD'),
    "to_date": moment().format('YYYY-MM-DD'),
    "game_type": "All",
  })

  const getAccountStatement = async (e) => {
    console.log('formData', formData)

    var data = JSON.stringify(formData);
    //  console.log(data);
    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}reports/aurafilteraccountStatement`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setData(response.data.resultData);

      })
      .catch(function (error) {
        console.log(error);
      });


  }

  useEffect(() => {
    getAccountStatement();
  }, [])

  const columns = [
    // {
    //   title: 'Sr.no',
    //   dataIndex: '',
    //   render: (_, record, index) => (
    //     <span className=' '>
    //       {index}
    //     </span>
    //   )
    // },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      sorter: true,
      render: (_, record) => (
        <span style={{ color: "#212529", fontWeight: 800, fontSize: '0.813rem' }}>{new Date(record.createdAt).toLocaleString()}</span>
      ),
    },
    {
      title: 'Remark',
      dataIndex: 'remarks',
      sorter: true,
      render: (_, record) => (
        <span style={{ color: "#212529", fontWeight: 800, fontSize: '0.813rem' }}>{record.remarks}</span>
      ),
    },
    {
      title: 'Deposit',
      dataIndex: 'amount',
      sorter: true,
      render: (_, record) => (
        record.transaction_type ==
          "Credit" ?
          <span style={{ color: "#508d0e", fontWeight: 800, fontSize: '0.813rem' }}>{record.amount}</span>
          : '-'
      ),
    },
    {
      title: 'Withdraw',
      dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        record.transaction_type == "Debit" ?
          // record.amount
          <span style={{ color: "#d50000", fontWeight: 800, fontSize: '0.813rem' }}>{record.amount}</span>
          : '-'
      ),
    },
    {
      title: 'From/To',
      sorter: true,
      render: (_, record) => (
        <span style={{ color: "#212529", fontWeight: 800, fontSize: '0.813rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {record.from}
          <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 17V7L16 12L9 17Z" />
          </svg>
          {record.to}
        </span>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      sorter: true,
      render: (_, record) => (
        <span style={{ color: "#212529", fontWeight: 800, fontSize: '0.813rem' }} >{record.balance}</span>
      ),
    },
  ];

  const handleToDate = (newDate) => {
    if (newDate) {
      setFormData({
        ...formData,
        to_date: newDate
      })
    }
  };

  const handleFromDate = (newDate) => {
    if (newDate) {
      setFormData({
        ...formData,
        from_date: newDate
      })
    }
  };

  // const onSelectChange = (e) => {
  //   if (e == "All") {
  //     setFormData({
  //       ...formData,
  //       transaction_type: ""
  //     })
  //   } else if (e == "Deposit") {
  //     setFormData({
  //       ...formData,
  //       transaction_type: "Credit"
  //     })
  //   } else if (e == "Withdraw") {
  //     setFormData({
  //       ...formData,
  //       transaction_type: "Debit"
  //     })
  //   }

  // }

  return (
    <>
      <Header />
      <ExposureHeader />
      <div className="account-statment">
        <p className="open-para">
          <span>Account Statement</span>
        </p>

        {/* <div className="option-flex">


          <select className="select-dropdown for-ac">
            <option value="All">All</option>
            <option value="Deposit">Deposit</option>
            <option value="Withdraw">Withdraw</option>
          </select>



          <ul className="search">
            <li className="input">
              <input type="text" className="search-input" placeholder="search" />
            </li>
          </ul>
        </div> */}



        <div className="form" style={{ marginTop: "10px" }}>
          <form>
            <div className="date-time">
              <input type="date" name="from_date" onChange={(e) => handleFromDate(e.target.value)} value={formData?.from_date || new Date()} className="d-and-t" />
              <input type="date" name="to_date" onChange={(e) => handleToDate(e.target.value)} value={formData?.to_date || new Date()} className="d-and-t" />
            </div>
            <div className="new-buttons">
              <input type="button" value={'submit'} onClick={() => { getAccountStatement() }} className="btn-submit" />
              <input type="button" value={'reset'} className="btn-submit" />
            </div>
          </form>
        </div>

      </div>


      <div className="table-container">
        {/* <table className="scrollable-table">
          <thead>
            <tr>
              <th>Sr.no</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Deposit</th>
              <th>Withdraw</th>
              <th>From / To</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2024-09-15 13:52:48</td>
              <td>
                <span className="table-match">
                  Deposit 100
                </span>
              </td>
              <td className="credit">100</td>
              <td className="debit">0 0</td>
              <td>Admin - User1</td>
              <td>100</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2024-09-15 13:52:48</td>
              <td>
                <span className="table-match">
                  Deposit 100
                </span>
              </td>
              <td className="credit">100</td>
              <td className="debit">0 0</td>
              <td>Admin - User1</td>
              <td>200</td>
            </tr>
            <tr>
              <td>3</td>
              <td>2024-09-15 13:52:48</td>
              <td>
                <span className="table-match">
                  Deposit 100
                </span>
              </td>
              <td className="credit">100</td>
              <td className="debit">0 0</td>
              <td>Admin - User1</td>
              <td>300</td>
            </tr>

          </tbody>
        </table> */}
        <Table
          columns={columns}
          dataSource={data}
          pagination={true}
          bordered={true}
        />
      </div>
      <Empty />
      <Footer />
    </>
  );
};

export default AccountStatement;
