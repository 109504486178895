import React, { useState } from "react";
import "./Profile.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import { FaUserTie } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import AddWithdrawDetails from "../AddWithdrawDetails/AddWithdrawDetails"; // Import your form
import Helper from "../../helper";
import ExposureHeader from '../ExposureHeader/ExposureHeader';

const Profile = () => {
  const userInfo = Helper();

  const [showEditPopup, setShowEditPopup] = useState(false); // State to handle Edit form popup visibility
  const [showPopup, setShowPopup] = useState(false); // State to handle AddWithdrawDetails popup visibility
  const [editingSection, setEditingSection] = useState(null); // Track which section is being edited

  const [bankDetails, setBankDetails] = useState({
    name: "Example",
    accountNumber: "12345678xxxx",
    ifscCode: "SBIN000002",
    bankName: "State Bank of India",
  });

  const [UPIDetails, setUPIDetails] = useState({
    upi_id: "1234fgd",
    phoneNumber: "12345678xxxx",
  });

  // Handle changes in the Bank input fields during editing
  const handleBankInputChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({
      ...bankDetails,
      [name]: value,
    });
  };

  // Handle changes in the UPI input fields during editing
  const handleUPIInputChange = (e) => {
    const { name, value } = e.target;
    setUPIDetails({
      ...UPIDetails,
      [name]: value,
    });
  };

  // Toggle the AddWithdrawDetails popup
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Toggle the Edit form popup and set the section to edit (either "bank" or "upi")
  const toggleEditPopup = (section) => {
    setEditingSection(section); // Set the section (either "bank" or "upi")
    setShowEditPopup(!showEditPopup); // Toggle the popup
  };

  return (
    <div>
      <Header />
      <ExposureHeader/>
      <div className="open-bet">
        <p className="open-para">
          <span>Profile</span>
        </p>
        <div className="profile-container">
          <div className="small-card">
            <FaUserTie size={100} color="orange" style={{ marginRight: 0 }} />
            {/* Account info below the icon */}
            <div className="account-info">
              <p>Account</p>
              <span>{userInfo?.user_name}</span>
            </div>
          </div>
          <div className="withdraw-button">
            <button onClick={togglePopup}>Add Withdraw Details</button> {/* Show AddWithdrawDetails popup */}
          </div>

          {/* Bank Details Section */}
          <div className="card-container">
            <h1>Bank Details</h1>
            <div className="main-card">
              <div className="bank-image">
                <img src="../Images/bank.png" alt="Bank" />
              </div>
              <div className="profile-details">
                <table>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>-</td>
                      <td>{bankDetails.name}</td>
                    </tr>
                    <tr>
                      <td>A/c Number</td>
                      <td>-</td>
                      <td>{bankDetails.accountNumber}</td>
                    </tr>
                    <tr>
                      <td>IFSC Code</td>
                      <td>-</td>
                      <td>{bankDetails.ifscCode}</td>
                    </tr>
                    <tr>
                      <td>Bank Name</td>
                      <td>-</td>
                      <td>{bankDetails.bankName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="action-buttons">
              <button className="edit-button" onClick={() => toggleEditPopup("bank")}>Edit</button>
              <button className="edit-button">Delete</button>
            </div>
          </div>

          {/* UPI Details Section */}
          <div className="card-container">
            <h1>UPI Details</h1>
            <div className="main-card">
              <div className="bank-image">
                <img src="../Images/bhim-upi.png" alt="UPI" />
              </div>
              <div className="profile-details">
                <table>
                  <tbody>
                    <tr>
                      <td>UPI Id</td>
                      <td>-</td>
                      <td>{UPIDetails.upi_id}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>-</td>
                      <td>{UPIDetails.phoneNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="action-buttons">
              <button className="edit-button" onClick={() => toggleEditPopup("upi")}>Edit</button>
              <button className="edit-button">Delete</button>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Popup */}
      {showEditPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Edit {editingSection === "bank" ? "Bank Details" : "UPI Details"}</h2>
            <form>
              {/* Conditionally render the form based on the editing section */}
              {editingSection === "bank" ? (
                <>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={bankDetails.name}
                      onChange={handleBankInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="accountNumber">A/c Number</label>
                    <input
                      type="text"
                      name="accountNumber"
                      value={bankDetails.accountNumber}
                      onChange={handleBankInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="ifscCode">IFSC Code</label>
                    <input
                      type="text"
                      name="ifscCode"
                      value={bankDetails.ifscCode}
                      onChange={handleBankInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="bankName">Bank Name</label>
                    <input
                      type="text"
                      name="bankName"
                      value={bankDetails.bankName}
                      onChange={handleBankInputChange}
                      className="form-control"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group">
                    <label htmlFor="upi_id">UPI ID</label>
                    <input
                      type="text"
                      name="upi_id"
                      value={UPIDetails.upi_id}
                      onChange={handleUPIInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={UPIDetails.phoneNumber}
                      onChange={handleUPIInputChange}
                      className="form-control"
                    />
                  </div>
                </>
              )}

              <div className="action-buttons">
                <button
                  type="button"
                  className="save-button"
                  onClick={toggleEditPopup} // Save the changes and close the popup
                >
                  Save
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={toggleEditPopup} // Close the popup without saving
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Conditionally render the AddWithdrawDetails popup */}
      {showPopup && <AddWithdrawDetails togglePopup={togglePopup} />}

      <div className="empty"></div>

      <Footer />
    </div>
  );
};

export default Profile;
