import React from "react";
import "./Rules.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from '../ExposureHeader/ExposureHeader';

const Rules = () => {
  return (
    <div>
      <Header />
      <ExposureHeader/>
      <div className="open-bet">
        <p className="open-para">
          <span>Rules</span>
        </p>
        <div className="rules">
          <h4>THE SITE RULES AND REGULATIONS</h4>
          <h5>THE SITE RULES AND REGULATIONS</h5>
          <p>
            These Rules and Regulations <strong>("Rules")</strong> are part of
            the Site's terms and conditions. The Rules apply to all bets placed
            on this online betting platform <strong>("Site")</strong>. The Rules
            consist of the following:
          </p>
          <ol className="ol-list">
            <li>This INTRODUCTION section (Part A);</li>
            <li>The GENERAL RULES (set out in Part B below); and</li>
            <li>
              The SPECIFIC SPORTS RULES (set out in Part C below - these apply
              to certain sports).
            </li>
          </ol>
          <h5>
            The General Rules apply to all bets unless stated otherwise in the
            Specific Sports Rules. If there is any inconsistency between the
            Specific Sports Rules and the General Rules, the Specific Sports
            Rules shall prevail.
          </h5>

          <h5>
            The rules governing how markets are offered, managed and/or settled
            are not the same for every market on each product. In certain
            circumstances, a bet that is settled as a winner on one product may
            be settled as a loser on the other product (and vice versa).
            Additionally, different settlement rules may apply so that, for
            example, bets that are a winner on one product may be settled as a
            dead heat or be voided on the other product. Customers must ensure
            that they familiarise themselves with the relevant rules that apply
            to the bets that they place on the Site.
          </h5>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Rules;
