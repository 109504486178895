import React, { useState, useEffect } from "react";
import "./Withdraw.css";
// import "../../../src/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFileUpload } from "react-icons/fa";
import Header from "../header/Header";
import ExposureHeader from '../ExposureHeader/ExposureHeader';
import Appconfig from '../../config/config'
import Helper from "../../helper";
import axios from 'axios';
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal, Input, Table, Image, Button, Switch } from 'antd';
import { getPaymentMethods } from '../../redux/slice/paymentMethod/paymentMethodSlice';
import { useSelector, useDispatch } from 'react-redux';



const Withdraw = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInfo = Helper();
  const userInfos = useSelector((state) => state.paymentMethods); // Use selector to access state
  const paymentMethod = userInfos?.paymentMethods || [];
  console.log('paymentMethod', paymentMethod)

  const [balance, setBalance] = useState(0)
  const [exposure, setExposure] = useState(0)
  let balanceWithExp = balance - Math.abs(exposure);


  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("new-account"); // Set default to 'new-account'
  const [isSubmitted, setIsSubmitted] = useState(false); // State for form submission
  const [buttonText, setButtonText] = useState("Submit"); // State for button text
  const [showPaymentMethods, setPaymentMethods] = useState(false);
  const [showAddAccountForm, setAddAccountForm] = useState(false);
  const [showBankForm, setShowBankForm] = useState('');
  const [showUpiForm, setShowUpiForm] = useState('');
  const [showEditPopup, setShowEditPopup] = useState(false); // State to handle Edit form popup visibility
  const [editingSection, setEditingSection] = useState(null); // Track which section is being edited
  const [showPreviousAccount, setShowPreviousAccount] = useState(false);
  const [amountValue, setAmountValue] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [bankName, setBankName] = useState("");

  const [upiId, setUpiId] = useState("");
  const [upiHolder, setUpiHolder] = useState("");
  const [button, setButton] = useState(false)



  const [AcNumValidationError, setAcNumValidationError] = useState("Please enter account number");
  const [AcHolderValidationError, setAcHolderValidationError] = useState("Please enter account holder name");
  const [IfscValidationError, setIfscValidationError] = useState("Please enter ifsc code");
  const [BankValidationError, setBankValidationError] = useState("Please enter bank name");
  const [UPIValidationError, setUPIValidationError] = useState("Please enter id");
  const [HolderValidationError, setHolderValidationError] = useState("Please enter holder name");

  const [withdrawHistory, setWithdrawHistory] = useState([]);



  const [bankDetails, setBankDetails] = useState({
    name: "Example",
    accountNumber: "12345678xxxx",
    ifscCode: "SBIN000002",
    bankName: "State Bank of India",
  });

  const [UPIDetails, setUPIDetails] = useState({
    upi_id: "1234fgd",
    phoneNumber: "12345678xxxx",
  });

  // Handle changes in the Bank input fields during editing
  const handleBankInputChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({
      ...bankDetails,
      [name]: value,
    });
  };


  useEffect(() => {
    getUserPaymentMethods();
  }, [])

  const getUserPaymentMethods = () => {
    dispatch(getPaymentMethods({
      site_code: Appconfig.sitecodes,
      user_id: userInfo?._id
    }));
  }


  useEffect(() => {
    if (userInfo) {
      getBalance();
    }
  }, [userInfo])

  function getBalance() {
    var data = JSON.stringify({
      user_id: userInfo?._id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}ledger/getUserBalance`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result == 0) {
        } else {
          setBalance(response.data.resultData?.balance)
          setExposure(response.data.resultData?.exposure)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  // Handle changes in the UPI input fields during editing
  const handleUPIInputChange = (e) => {
    const { name, value } = e.target;
    setUPIDetails({
      ...UPIDetails,
      [name]: value,
    });
  };

  const toggleEditPopup = (section) => {
    setEditingSection(section); // Set the section (either "bank" or "upi")
    setShowEditPopup(!showEditPopup); // Toggle the popup
  };

  function deletePaymentDetails(id) {
    var data = JSON.stringify({
      id: id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}paymentMethod/deleteType`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result) {
          toast.success(response.data.resultMessage, { autoClose: 3000 });
          getUserPaymentMethods();
        } else {
          toast.error(response.data.resultMessage, { autoClose: 3000 });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handlePreviousAccount = () => {
    setShowPreviousAccount(!showPreviousAccount);
    setPaymentMethods(false)
  }

  // Function to handle button click
  const handleButtonClick = (value) => {
    setInputValue(value);
  };

  // Function to handle payment option click
  const handleClick = (option) => {
    setSelectedOption(selectedOption === option ? null : option); // Toggle between opening/closing
  };

  const handlePaymentMethod = () => {
    setPaymentMethods(!showPaymentMethods);
    setShowPreviousAccount(false);

    // Hide all forms using an array of form setters
    [
      setShowUpiForm,
    ].forEach((setter) => setter(false));
  };

  const handleFormToggle = (form) => {
    setUpiId("");
    setUpiHolder("");
    setShowUpiForm(form);
  };

  // Button values
  const buttonValues = [500, 1000, 1500, 2000, 2500, 3000];

  const tickMark = (
    <svg
      width="58"
      height="45"
      viewBox="0 0 58 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M19.11 44.64L.27 25.81l5.66-5.66 13.18 13.18L52.07.38l5.65 5.65"
      />
    </svg>
  );

  function checkValidation() {

    if (showUpiForm == "Bank") {
      console.log('accountNumber', accountNumber);
      if (!accountNumber) {
        setAcNumValidationError('Please enter account number')
        document.querySelector('.acnum').classList.remove('hidden');
      }
      if (!accountHolder) {
        setAcHolderValidationError('Please enter holder name')
        document.querySelector('.acholder').classList.remove('hidden');
      }
      if (!ifscCode) {
        setIfscValidationError('Please enter ifsc code')
        document.querySelector('.ifsccode').classList.remove('hidden');
      }

      if (!bankName) {
        setBankValidationError('Please enter bank name')
        document.querySelector('.bankname').classList.remove('hidden');
      }

      if (accountNumber && accountHolder && ifscCode && bankName) {
        return true
      }
      return false;
    } else {
      if (!upiId) {
        setUPIValidationError('Please enter upi id')
        document.querySelector('.upiid').classList.remove('hidden');
      }
      if (!upiHolder) {
        setHolderValidationError('Please enter holder name')
        document.querySelector('.holder').classList.remove('hidden');
      }
      if (upiId && upiHolder) {
        return true
      }
      return false;
    }
  }

  // Handle submit click and toggle button text & style
  const handleSubmitClick = async (e) => {
    e.preventDefault();

    if (userInfo && showUpiForm && amountValue) {

      if (checkValidation()) {

        const formData = new FormData();

        formData.append("master_id", userInfo?.master_id);
        formData.append("user_id", userInfo?._id);
        formData.append("site_code", userInfo?.site_code);
        formData.append("user_name", userInfo?.user_name);
        formData.append("type", showUpiForm);
        formData.append("bank_name", bankName);
        formData.append("account_no", accountNumber);
        formData.append("account_holder_name", accountHolder);
        formData.append("ifsc_code", ifscCode);
        formData.append("holder_name", upiHolder);
        formData.append("upi_id", upiId);
        formData.append("amount", amountValue);

        var config = {
          method: 'post',
          url: `${Appconfig.apiUrl}withdraw/addWithdrawRequest`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: formData
        };
        axios(config)
          .then(function (response) {
            if (response.data.result) {
              setButtonText("Thanks");
              setIsSubmitted(true);
              toast.success(response.data.resultMessage, { autoClose: 3000 });

              setTimeout(() => {
                navigate("/");
              }, 2000);

            } else {
              toast.error(response.data.resultMessage, { autoClose: 3000 });
            }

          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } else {
      toast.error("Please select payment method or fill all the fields!", { autoClose: 3000 });
    }
  };

  function cancelWithdrawRequest(record, status) {
    setButton(true)
    var data = JSON.stringify({
      record: record,
      status: status,
      master_details: userInfo,
    });
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}withdraw/confirmWithdraw`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result) {
          toast.success(response.data.resultMessage, { autoClose: 3000 });
          getWithdrawHistory();
        } else {
          toast.error(response.data.resultMessage, { autoClose: 3000 });
        }
        setButton(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const historyColumns = [
    {
      title: 'User Name',
      sorter: true,
      render: (_, record) => (
        <span className=''>
          {record?.user_name}
        </span>
      ),
    },
    {
      title: 'User Id',
      sorter: true,
      render: (_, record) => (
        <span className=''>
          {record?.user_id}
        </span>
      ),
    },
    {
      title: 'Type',
      sorter: true,
      render: (_, record) => (
        <span className=''>
          {record?.type}
        </span>
      ),
    },
    ...(withdrawHistory.some((record) => record.bank_name) // Check if any record has a bank name
      ? [
        {
          title: 'Bank Name',
          sorter: true,
          render: (_, record) => (
            <span className=''>
              {record?.bank_name || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(withdrawHistory.some((record) => record.account_no) // Check if any record has a bank name
      ? [
        {
          title: 'Account Num',
          sorter: true,
          render: (_, record) => (
            <span className=''>
              {record?.account_no || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(withdrawHistory.some((record) => record.account_holder_name) // Check if any record has a bank name
      ? [
        {
          title: 'Account Holder Name',
          sorter: true,
          render: (_, record) => (
            <span className=''>
              {record?.account_holder_name || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(withdrawHistory.some((record) => record.ifsc_code) // Check if any record has a bank name
      ? [
        {
          title: 'IFSC Code',
          sorter: true,
          render: (_, record) => (
            <span className=''>
              {record?.ifsc_code || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(withdrawHistory.some((record) => record.upi_id) // Check if any record has a bank name
      ? [
        {
          title: 'UPI Id',
          sorter: true,
          render: (_, record) => (
            <span className=''>
              {record?.upi_id || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(withdrawHistory.some((record) => record.holder_name) // Check if any record has a bank name
      ? [
        {
          title: 'Holder Name',
          sorter: true,
          render: (_, record) => (
            <span className=''>
              {record?.holder_name || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    {
      title: 'Amount',
      sorter: true,
      render: (_, record) => (
        <span className=''>
          {record?.amount}
        </span>
      ),
    },
    {
      title: 'Request Date',
      sorter: true,
      render: (_, record) => (
        <span className=''>
          {(new Date(record?.createdAt)).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Status',
      sorter: true,
      render: (_, record) => (
        <div className='' >
          {
            record?.status == 'Request' ?
              <button disabled={button} onClick={() => cancelWithdrawRequest(record, 'Cancel')} className='custom-button'>
                <span>Cancel</span>
              </button> : record?.status
          }
        </div>
      ),
    },
  ];

  const getWithdrawHistory = () => {

    let site_code = Appconfig.sitecodes;
    let data = JSON.stringify({
      site_code: site_code,
      user_id: userInfo?._id
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}withdraw/getWithdrawHistory`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result) {
          setWithdrawHistory(response.data.resultData);
        } else {
          toast.error(response.data.resultMessage, { autoClose: 3000 });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  function selectPaymentDetail(detail) {
    setBankName(detail?.bankName);
    setAccountNumber(detail?.accountNo);
    setAccountHolder(detail?.accountHolder);
    setIfscCode(detail?.ifscCode);
    setUpiId(detail?.upiId);
    setUpiHolder(detail?.holderName);
    setShowUpiForm(detail?.type);
  }


  useEffect(() => {
    getWithdrawHistory()
    handlePaymentMethod();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <Header />
      <ExposureHeader />
      <div className="deposit-withdraw">
        <p className="deposit">
          <span>Withdraw </span>
        </p>

        <div className="withdraw-payments-symbol">
          <h3 className="payments-options">Withdraw Funds</h3>
          <p className="see-more">
            This form is for withdrawing the amount from the main wallet only.
          </p>
          <ul className="withdraw-payments">
            <li
              className={`click-account ${selectedOption === "new-account" ? "active" : ""}`}
              onClick={() => handleClick("new-account")}
            >
              <button onClick={handlePaymentMethod}>Use New Account</button>
            </li>

            <li
              className={`click-account ${selectedOption === "previous-account" ? "active" : ""}`}
              onClick={() => {
                paymentMethod?.length > 0 && handleClick("previous-account");
              }}
            >
              <button
                onClick={handlePreviousAccount}
                disabled={!paymentMethod || paymentMethod.length === 0}
              >
                Use Previous Account
              </button>
            </li>

          </ul>
        </div>

        {showPaymentMethods && (
          <div className="payment-methods">
            <ul className="payment-types">
              {Appconfig.withdraw_account_type?.map((type, index) => (
                <button
                  className="payment-btn"
                  onClick={() => handleFormToggle(type)}
                >
                  <li className={`payment-apps ${type === showUpiForm ? "active" : ""}`} >
                    {
                      type == 'Bank' ? <img src="../Images/bank.png" alt={type} /> : ''
                    }
                    {
                      type == 'Gpay' ? <img src="../Images/G-pay.png" alt={type} /> : ''
                    }
                    {
                      type == 'UPI' ? <img src="../Images/UPI-Color.png" alt={type} /> : ''
                    }
                    {
                      type == 'Phonepe' ? <img src="../Images/phonepe.png" alt={type} /> : ''
                    }
                    {
                      type == 'Paytm' ? <img src="../Images/paytm.png" alt={type} /> : ''
                    }
                  </li>
                </button>
              ))}

            </ul>
          </div>
        )}

        {showPreviousAccount && (
          <div className="profile-container">
            {
              paymentMethod?.length > 0 && paymentMethod?.map((method, index) => (
                <>
                  <div key={index} className={`card-container ${showUpiForm == method?.type ? 'active' : ''}`} onClick={() => selectPaymentDetail(method)}>
                    <h1>{method?.type} Details</h1>
                    <div className="main-card">
                      <div className="bank-image">
                        {
                          method?.type == 'Bank' ? <img src="../Images/bank.png" alt={method?.type} /> : ''
                        }
                        {
                          method?.type == 'Gpay' ? <img src="../Images/G-pay.png" alt={method?.type} /> : ''
                        }
                        {
                          method?.type == 'UPI' ? <img src="../Images/UPI-Color.png" alt={method?.type} /> : ''
                        }
                        {
                          method?.type == 'Phonepe' ? <img src="../Images/phonepe.png" alt={method?.type} /> : ''
                        }
                        {
                          method?.type == 'Paytm' ? <img src="../Images/paytm.png" alt={method?.type} /> : ''
                        }
                      </div>
                      <div className="profile-details">
                        <table>
                          <tbody>
                            <tr>
                              <td>HOLDER NAME</td>
                              <td>:</td>
                              <td>{method?.type == "Bank" ? method?.accountHolder : method?.holderName}</td>
                            </tr>
                            <tr>
                              <td>{method?.type == "Bank" ? 'A/C NUMBER' : 'UPI ID'}</td>
                              <td>:</td>
                              <td>{method?.type == "Bank" ? method?.accountNo : method?.upiId}</td>
                            </tr>
                            {
                              method?.type == "Bank" ? (
                                <>
                                  <tr>
                                    <td>IFSC CODE</td>
                                    <td>:</td>
                                    <td>{method?.ifscCode}</td>
                                  </tr>
                                  <tr>
                                    <td>BANK NAME</td>
                                    <td>:</td>
                                    <td>{method?.bankName}</td>
                                  </tr>
                                </>
                              )
                                :
                                (
                                  <></>
                                )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="action-buttons">
                      {/* <button className="edit-button" onClick={() => toggleEditPopup("bank")}>Edit</button> */}
                      <button className="edit-button" onClick={() => deletePaymentDetails(method?._id)}>Delete</button>
                    </div>
                  </div>
                </>
              )
              )
            }
          </div>
        )}

        <div className="payment-details">

          <div className="details">
            <form onSubmit={handleSubmitClick}>
              <h4>Available to Withdrawl: &#8377;  {balance && Number(balanceWithExp).toFixed(2)}</h4>
              <div className="methods-heading">
                <p>{!showPreviousAccount && 'Submit Your Account Details'}</p>
              </div>
              <div className="withdraw-form-input">
                <p>
                  <input
                    type="number"
                    value={amountValue}
                    onChange={(e) => setAmountValue(e.target.value)}
                    placeholder=" &#8377;  &nbsp; Enter Amount"
                  />
                </p>
              </div>
              {(showUpiForm == "Bank" && !showPreviousAccount) && (
                <>
                  <div className="withdraw-form-input">
                    {/* <p>IFSC Code<span className='red'>*</span></p> */}
                    <p>
                      <input type="text" value={ifscCode}
                        onChange={(e) => setIfscCode(e.target.value)} placeholder=" Enter IFSC Code" />
                      <span className="ifsccode error-message hidden">
                        <div className=''>{IfscValidationError}</div>
                      </span>
                    </p>
                  </div>
                  <div className="withdraw-form-input">
                    {/* <p>Account No<span className='red'>*</span></p> */}
                    <p>
                      <input type="number" value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)} placeholder=" Enter Account Number" />
                      <span className="acnum error-message hidden">
                        <div className=''>{AcNumValidationError}</div>
                      </span>
                    </p>
                  </div>
                  <div className="withdraw-form-input">
                    {/* <p>Account No<span className='red'>*</span></p> */}
                    <p>
                      <input type="text" value={bankName}
                        onChange={(e) => setBankName(e.target.value)} placeholder=" Enter Bank Name" />
                      <span className="bankname error-message hidden">
                        <div className=''>{BankValidationError}</div>
                      </span>
                    </p>
                  </div>
                  {/* <div className="withdraw-form-input">
                    <p>
                      <input
                        type="number"
                        placeholder=" Re-enter Account Number"
                      />
                    </p>
                  </div> */}
                  <div className="withdraw-form-input">
                    {/* <p>Account Name<span className='red'>*</span></p> */}
                    <p>
                      <input type="text" value={accountHolder}
                        onChange={(e) => setAccountHolder(e.target.value)} placeholder=" Enter Account Name" />
                      <span className="acholder error-message hidden">
                        <div className=''>{AcHolderValidationError}</div>
                      </span>
                    </p>
                  </div>
                </>
              )}
              {(showUpiForm == "UPI" || showUpiForm == "Phonepe" || showUpiForm == "Gpay" || showUpiForm == "Paytm") && (!showPreviousAccount) && (
                <>
                  <div className="withdraw-form-input">
                    <p>
                      <input type="text" value={upiId}
                        onChange={(e) => setUpiId(e.target.value)} placeholder={`Enter ${showUpiForm} ID`} />
                      <span className="upiid error-message hidden">
                        <div className=''>{UPIValidationError}</div>
                      </span>
                    </p>
                  </div>
                  <div className="withdraw-form-input">
                    <p>
                      <input type="text" value={upiHolder}
                        onChange={(e) => setUpiHolder(e.target.value)} placeholder={`Enter ${showUpiForm} Holder Name`} />
                      <span className="holder error-message hidden ">
                        <div className=''>{HolderValidationError}</div>
                      </span>
                    </p>
                  </div>
                </>
              )}
              {/* <div className="otp-div withdraw-form-input">
                <p className="otp">Click on get OTP to verify your account</p>
                <p>
                  <input type="number" placeholder=" +91 999000999" />
                  <button className="button-container">Get OTP</button>
                </p>
              </div> */}
              <div className="agree">
                <p>
                  <input type="checkbox" />{" "}
                  <span className="terms-conditions">
                    I have read the term and condition
                  </span>{" "}
                  <a className="terms-conditions-link" href="#">
                    the terms of payments and withdrawl policy
                  </a>
                </p>
              </div>
              <div className="section-submit-button">
                <button
                  id="btn"
                  type="submit"
                  disabled={isSubmitted ? true : false}
                  className={`payment-submit-button ${isSubmitted ? "active" : ""
                    }`}
                >
                  <p className="submit-button-text">{buttonText}</p>
                  <div className="checked">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                    >
                      <path
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>


            </form>
          </div>
        </div>


      </div>

      <div style={{ padding: "10px" }}>
        <div>
          <h3 style={{ color: 'black' }}>Withdraw History</h3>
        </div>

        <div className="deposit-history" style={{ overflowX: 'auto' }}>
          <Table
            columns={historyColumns}
            className='downline-list'
            bordered
            dataSource={withdrawHistory}
            pagination={true}
          />
        </div>
      </div>
    </>
  );
};

export default Withdraw;
