import React, { useState, useEffect } from 'react';
import './ExposureHeader.css';
// import "../../../src/App.css";
import { HiCurrencyRupee } from "react-icons/hi";
import Helper from "../../helper";
import Appconfig from '../../config/config'
import axios from 'axios';
import { useSelector } from 'react-redux';

const ExposureHeader = () => {
  const userInfo = Helper();
  const betData = useSelector((state) => state.data); // Use selector to access state

  const [balance, setBalance] = useState(0)
  const [exposure, setExposure] = useState(0)
  let balanceWithExp = balance - Math.abs(exposure);


  useEffect(() => {
    if (userInfo) {
      getBalance();
    }
  }, [userInfo, betData])

  function getBalance() {
    var data = JSON.stringify({
      user_id: userInfo?._id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}ledger/getUserBalance`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result == 0) {
        } else {
          setBalance(response.data.resultData?.balance)
          setExposure(response.data.resultData?.exposure)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  return (
    <>
      {
        userInfo &&
        <div>
          <div className="main-header">
            <div className="coin"><HiCurrencyRupee size={20} color=" #FFD700" style={{ marginRight: 5, marginLeft: 0, marginTop: 1 }} /></div><span className='main-balance'>Main: <p>{balance && Number(balanceWithExp).toFixed(2)}</p></span>
            <span className='exposure-balance'>Exposure: <p>{exposure && Number(exposure).toFixed(2)}</p></span>
          </div>
        </div>
      }
    </>
  );
}

export default ExposureHeader;
