import { useState, useEffect } from 'react';
import { Input, Table, Select, Pagination } from 'antd';
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import Appconfig from '../../config/config'
import { useDispatch, useSelector } from 'react-redux';
import Helper from "../../helper";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from '../ExposureHeader/ExposureHeader';

function ProfitLossEvents() {
    const navigate = useNavigate();
    const params = useParams();

    const userInfo = Helper(); // get login user details
    const [filteredData, setFilteredData] = useState([]);

    const [formData, setFormData] = useState({
        "user_id": userInfo && userInfo?._id,
        "event_type": params && params?.event_type_id,
        "from_date": params && params?.from_date,
        "to_date": params && params?.to_date,
    })

    useEffect(() => {
        filterProfitLossByEvents();
    }, [])


    const filterProfitLossByEvents = async (e) => {

        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/auraProfitLossByEvents`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    console.log(response.data.resultData);
                    setFilteredData(response.data.resultData);
                    // setData(response.data.resultData);
                } catch (e) {
                    // postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                // postErrorToslack(error.message);
            });
    }


    const columns = [
        {
            title: 'Sport Name',
            sorter: true,

            render: (_, record) => (
                record.event_type == 4 ?
                    (
                        <div className=' '>
                            Cricket
                        </div>
                    ) : record.event_type == 2 ? (
                        <div className=' '>
                            Tennis
                        </div>
                    ) : record.event_type == 1 ? (
                        <div className=' '>
                            Soccer
                        </div>
                    ) : record.event_type == 7 ? (
                        <div className=' '>
                            Horse Racing
                        </div>
                    ) : record.event_type == 1004 || record.event_type == 1005 || record.event_type == 1007 || record.event_type == 1008 || record.event_type == 1009 ? (
                        <div className=' '>
                            Casino
                        </div>
                    ) : record.event_type == 4339 ? (
                        <div className=' '>
                            Greyhound Racing
                        </div>
                    ) : record.event_type == 27979456 ? (
                        <div className=' '>
                            Kabaddi
                        </div>
                    ) : record.event_type == 7522 ? (
                        <div className=' '>
                            Basketball
                        </div>
                    ) : record.event_type == 2378961 ? (
                        <div className=' '>
                            Politics
                        </div>
                    ) : record.event_type == 66101 ? (
                        <div className=' '>
                            Virtual
                        </div>
                    ) : record.event_type == 66104 ? (
                        <div className=' '>
                            Lottery
                        </div>
                    ) : (
                        <div className=' '>
                            Other
                        </div>
                    )
            ),
        }, {
            title: 'Event Name',
            sorter: true,

            render: (_, record) => (
                <Link className='' to={`/profit-loss-event-market/${record.match_id}/${params?.from_date}/${params?.to_date}`}>
                    {record.event_name}
                </Link>
            ),
        },
        {
            title: 'Profit/Loss',
            dataIndex: 'name',
            sorter: true,
            render: (_, record) => (
                record.totalPL < 0 ? (
                    <span className=' '>
                        {record?.totalPL?.toFixed(2)}
                    </span>
                ) : (
                    <span className=' '>{
                        record?.totalPL?.toFixed(2)
                    }</span>
                )
            )
        },
        // {
        //     title: 'Commission',
        //     dataIndex: 'name',
        //     sorter: true,
        //     render: (_, record) => (
        //         <span className=' '>{
        //             record?.commissionAmount?.toFixed(2)
        //         }</span>
        //     )
        // },
        {
            title: 'Total P&L',
            dataIndex: 'name',
            sorter: false,
            render: (_, record) => (
                record.totalPL < 0 ? (
                    <span className=''>
                        {(record?.totalPL + record?.commissionAmount).toFixed(2)}
                    </span>
                ) : (
                    <span className=' '>{
                        (record?.totalPL + record?.commissionAmount).toFixed(2)
                    }</span>
                )
            )
        },
    ];

    return (
        <>
            <Header />
            <ExposureHeader/>
            <div className="account-statment">
                <p className="open-para">
                    <span>Profit & Loss By Events</span>
                </p>
            </div>


            <div className="table-container">
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={true}
                    bordered={true}
                />
            </div>

            <Footer />
        </>
    );
}

export default ProfitLossEvents;
