import React, { useState } from "react";
import "./ProfitAndLoss.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from "../ExposureHeader/ExposureHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from 'react-router-dom';


const ProfitAndLoss2 = () => {

  const [profitandloss3, setProfitAndLoss3] = useState(false);
  const navigate = useNavigate();

  const handleProfitAndLoss3 = () =>{
    setProfitAndLoss3(true)
    navigate('/profitandloss3')
  }
  return (
    <>
      <Header />
      <ExposureHeader/>
      <div className="account-statment">
        <p className="open-para">
          <span>Profit And Loss</span>
        </p>

        <div className="option-flex">
          <ul className="options">
            <li>Show</li>
            <li>
              <select>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>500</option>
              </select>
            </li>
            <li>entries</li>
          </ul>
          <ul className="search">
            {/* <li>Search:</li> */}
            <li className="input">
              <input type="text" className="search-input" placeholder="search"/>
            </li>
          </ul>
        </div>
        

      </div>


        <div className="table-container">
          <table className="scrollable-table">
            <thead>
              <tr>
                <th>Sr.no</th>
                <th>Date</th>
                <th>Market Name</th>
                <th>Match P & L</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {/* Sample data for the table */}
              <tr>
                <td>1</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <button className="table-match" onClick={handleProfitAndLoss3}>
                    INDIA vs PAK / Match Odds
                  </button>
                </td>
                <td className="credit">100.00</td>
                
              </tr>
              <tr>
                <td>2</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <button onClick={handleProfitAndLoss3} className="table-match">
                  INDIA vs PAK / Fancy / 6 over IND
                  </button>
                </td>
                <td className="credit">100.00</td>
                
              </tr>
              <tr>
                <td>3</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <button onClick={handleProfitAndLoss3} className="table-match">
                  INDIA vs PAK / Fancy / 10 over IND
                  </button>
                </td>
                <td className="credit">100.00</td>
                
              </tr>

              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
        
      <Footer />
    </>
  );
};

export default ProfitAndLoss2;
