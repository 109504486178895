import { useState, useEffect } from 'react';
import {  Table,  Pagination } from 'antd';
import {  useParams } from "react-router-dom";
import axios from 'axios';
import Appconfig from '../../config/config'
import { useDispatch,useSelector } from 'react-redux';
import Helper from "../../helper";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from '../ExposureHeader/ExposureHeader';


function ProfitLossBetHistory() {
    const params = useParams();
    const userInfo = Helper(); // get login user details

    const [filteredData, setFilteredData] = useState([]);
    const [formData, setFormData] = useState({
        "user_id": userInfo && userInfo?._id,
        "match_id": params && params?.match_id,
        "market_id": params && params?.market_id,
        "from_date": params && params?.from_date,
        "to_date": params && params?.to_date,
    })


    const columns = [
        {
            title: 'Sport Name',
            sorter: true,

            render: (_, record) => (
                record.event_type == 4 ?
                    (
                        <div className=' '>
                            Cricket
                        </div>
                    ) : record.event_type == 2 ? (
                        <div className=' '>
                            Tennis
                        </div>
                    ) : record.event_type == 1 ? (
                        <div className=' '>
                            Soccer
                        </div>
                    ) : record.event_type == 7 ? (
                        <div className=' '>
                            Horse Racing
                        </div>
                    ) : record.event_type == 1004 || record.event_type == 1005 || record.event_type == 1007 || record.event_type == 1008 || record.event_type == 1009 ? (
                        <div className=' '>
                            Casino
                        </div>
                    ) : record.event_type == 4339 ? (
                        <div className=' '>
                            Greyhound Racing
                        </div>
                    ) : record.event_type == 27979456 ? (
                        <div className=' '>
                            Kabaddi
                        </div>
                    ) : record.event_type == 7522 ? (
                        <div className=' '>
                            Basketball
                        </div>
                    ) : record.event_type == 2378961 ? (
                        <div className=' '>
                            Politics
                        </div>
                    ) : record.event_type == 66101 ? (
                        <div className=' '>
                            Virtual
                        </div>
                    ) : record.event_type == 66104 ? (
                        <div className=' '>
                            Lottery
                        </div>
                    ) : (
                        <div className=' '>
                            Other
                        </div>
                    )
            ),
        }, {
            title: 'Event Name',
            sorter: true,

            render: (_, record) => (
                <div className='' >
                    {record.event_name}
                </div>
            ),
        },
        {
            title: 'Market Name',
            sorter: true,

            render: (_, record) => (

                <div className=''>
                    {record.market_name}
                </div>

            ),
        },
        {
            title: 'Selection Name',
            sorter: true,

            render: (_, record) => (
                <div className=''>
                    {record.place_name}
                </div>
            ),
        },
        {
            title: 'Bet Type',
            sorter: true,

            render: (_, record) => (
                record.is_back ? (
                    <div className=''>
                        BACK
                    </div>
                ) : (
                    <div className=''>
                        Lay
                    </div>
                )

            ),
        },
        {
            title: 'User Price',
            sorter: true,

            render: (_, record) => (
                <div className=''>
                    {record.price_val}
                </div>
            ),
        },
        {
            title: 'Amount',
            sorter: true,

            render: (_, record) => (
                <div className=''>
                    {record.stake}
                </div>
            ),
        },

        {
            title: 'Profit/Loss',
            dataIndex: 'name',
            sorter: true,
            render: (_, record) => (
                record.totalPL < 0 ? (
                    <span className=''>
                        {record?.totalPL?.toFixed(2)}
                    </span>
                ) : (
                    <span className=''>{
                        record?.totalPL?.toFixed(2)
                    }</span>
                )
            )
        },

        {
            title: 'Place Date',
            sorter: true,

            render: (_, record) => (
                <div className=''>
                    {new Date(record.createdAt).toLocaleString()}
                </div>
            ),
        },
        {
            title: 'Match Date',
            sorter: true,

            render: (_, record) => (
                <div className=''>
                    {new Date(record.createdAt).toLocaleString()}
                </div>
            ),
        },
    ];

    useEffect(() => {
        filterProfitLossBetHistory();
    }, [])


    const filterProfitLossBetHistory = async (e) => {

        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/auraProfitLossBetHistory`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    console.log(response.data.resultData);
                    setFilteredData(response.data.resultData);

                } catch (e) {
                    // postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                // postErrorToslack(error.message);
            });
    }


    return (
        <>
            <Header />
            <ExposureHeader/>
            <div className="account-statment">
                <p className="open-para">
                    <span>Bet History</span>
                </p>
            </div>


            <div className="table-container">
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={true}
                    bordered={true}
                />
            </div>

            <Footer />
        </>
    );

}

export default ProfitLossBetHistory;
